var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('location'),_c('router-view'),_c('div',{staticClass:"page p36 wrap-1200"},[_c('b-form',{staticClass:"filter-group row row-cols-1 row-cols-sm-auto g-2 justify-content-end",on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"col"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchData.searchtype),expression:"searchData.searchtype"}],attrs:{"aria-label":"Select one"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.searchData, "searchtype", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v(_vm._s(_vm.$t("filter.common.id")))]),_c('option',{attrs:{"value":"2"}},[_vm._v(_vm._s(_vm.$t("filter.common.nickname")))]),_c('option',{attrs:{"value":"3"}},[_vm._v(_vm._s(_vm.$t("filter.common.title")))])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchData.searchname),expression:"searchData.searchname"}],staticClass:"form-control",attrs:{"type":"text","value":"Search"},domProps:{"value":(_vm.searchData.searchname)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.searchData, "searchname", $event.target.value)}}}),_c('button',{staticClass:"btn btn-primary btn-md icon-search",attrs:{"type":"submit"}})])])]),(_vm.data_yn)?_c('div',{staticClass:"row g-0"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"col-lg-4"},[_c('router-link',{staticClass:"d-block",attrs:{"to":{
            name: 'NetizenLessonView',
            params: { id: item.swingno },
            query: {
              searchtype: _vm.searchData.searchtype,
              searchname: _vm.searchData.searchname,
            },
          }}},[_c('div',{staticClass:"video-box"},[_c('span',{staticClass:"icon-play"}),(_vm.browser === 'safari')?_c('img',{staticClass:"d-block w-100",attrs:{"src":"/img/video_img.png"}}):_c('video',{staticClass:"d-block w-100",attrs:{"src":item.path + '/' + item.swingmovie}})]),_c('div',{staticClass:"bg-white"},[_c('h5',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"text-black"},[_vm._v(_vm._s(item.nickname))]),_c('p',[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("textsms")]),_vm._v(_vm._s(_vm._f("comma")(item.replycnt))+" ")])])])],1)}),0):_c('div',{staticClass:"row g-0 text-center mb-1"},[_c('div',{staticClass:"col-lg-12"},[_vm._v(_vm._s(_vm.$t("text.common.no_data")))])]),(_vm.page_total > 1)?_c('b-pagination-nav',{attrs:{"link-gen":_vm.linkGen,"number-of-pages":_vm.page_total,"use-router":"","aria-label":"Page navigation","first-class":"prev","prev-class":"prev","next-class":"next","last-class":"next","hide-ellipsis":"","limit":"10"},scopedSlots:_vm._u([{key:"first-text",fn:function(){return [_c('i',{staticClass:"material-icons"},[_vm._v("keyboard_double_arrow_left")])]},proxy:true},{key:"prev-text",fn:function(){return [_c('i',{staticClass:"material-icons"},[_vm._v("navigate_before")])]},proxy:true},{key:"next-text",fn:function(){return [_c('i',{staticClass:"material-icons"},[_vm._v("navigate_next")])]},proxy:true},{key:"last-text",fn:function(){return [_c('i',{staticClass:"material-icons"},[_vm._v("keyboard_double_arrow_right")])]},proxy:true},{key:"page",fn:function({ page, active }){return [(active)?_c('a',[_vm._v(_vm._s(page))]):_c('a',[_vm._v(_vm._s(page))])]}}],null,false,3228152692),model:{value:(_vm.searchData.pageno),callback:function ($$v) {_vm.$set(_vm.searchData, "pageno", $$v)},expression:"searchData.pageno"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }